import React from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import mail from "../images/mail.svg"
import padlockOpen from "../images/padlock-open.svg"
import { translate } from "../utils"

const RequestInfo = () => {
  return (
    <Layout>
      <div className="formWrapper">
        <Container>
          <Row className="justify-content-center">
            <Col lg="7">
              <div className="text-left">
                <h2 className="mb-4">
                  {translate('vul alstublieft uw vereiste informatie in')}
                </h2>
                <Form>
                  <Row>
                    <Col lg="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={16}
                              height={8}
                              className="img-fluid"
                              src={mail}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Email"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col lg="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={16}
                              height={8}
                              className="img-fluid"
                              src={mail}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Email"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col lg="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={16}
                              height={8}
                              className="img-fluid"
                              src={mail}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Email"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col lg="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={16}
                              height={8}
                              className="img-fluid"
                              src={mail}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Email"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col lg="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={11}
                              height={17}
                              className="img-fluid"
                              src={padlockOpen}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder={translate('wachtwoord')}
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col lg="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={11}
                              height={17}
                              className="img-fluid"
                              src={padlockOpen}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Password"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col lg="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={11}
                              height={17}
                              className="img-fluid"
                              src={padlockOpen}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Password"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col lg="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={11}
                              height={17}
                              className="img-fluid"
                              src={padlockOpen}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Password"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Form.Group
                    controlId="formBasicCheckbox"
                    className="d-flex justify-content-center mt-3"
                  >
                    <Form.Check
                      className="text-certer"
                      type="checkbox"
                      label={translate("alles is correct")}
                    />
                  </Form.Group>
                  <div className="d-md-flex justify-content-center mt-3 form-group row">
                  <Button variant="success" type="submit" className="button-yellow">
                    {translate('inloggen')}
                  </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default RequestInfo
